import ajaxBase from '@developers/frp-library/utils/ajax';
import { getToken } from '@developers/single-sign-on/getToken';

export const prefix = process.env.NEXT_PUBLIC_API;

function ajax(request) {
  const token = getToken();

  const requestWithToken = {...request, headers: { SSO: token }};

  return ajaxBase(requestWithToken);
}

export default ajax;
