import ajax from './common.js';
import { prefix } from './common.js';

export async function getCallsAjax(page = 1, filter = {}, sortField = '', direction = '') {
  return await ajax({
    url: prefix + '/calls',
    data: { page, ...filter, sortField, direction }
  });
}

export async function getCallAjax(id) {
  return await ajax({
    url: prefix + '/calls/' + id
  });
}

export async function getCallPromptsAjax(account_id) {
  return await ajax({
    url: prefix + '/calls/prompts',
    data: {account_id}
  });
}

export async function createCallAjax(data) {
  return await ajax({
    method: 'POST',
    url: prefix + '/calls',
    data
  });
}

export async function updateCallAjax(id, data) {
  return await ajax({
    method: 'PUT',
    url: prefix + '/calls/' + id,
    data
  });
}

export async function deleteCallAjax(id) {
  return await ajax({
    method: 'DELETE',
    url: prefix + '/calls/' + id
  });
}

export async function getCallNotesAjax(id) {
  return await ajax({
    url: prefix + '/calls/' + id + '/notes'
  });
}

export async function getCallHistoryAjax() {
  return await ajax({
    url: prefix + '/calls/history'
  });
}